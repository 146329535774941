import React, { Fragment, useState, useEffect } from "react";
import { officeImg, factoryImg, reportImg } from "../Elite/imageAssets";
import { useNavigate } from "react-router-dom";

const VisitorCard = () => {
  const navigate = useNavigate();
  const [visitorCardData, setVisitorCardData] = useState([
    {
      name: "Visit to Office",
      imageUrl: officeImg,
      pageUrl: "/office/CreateNewVisitor",
      isHoverCard: false,
    },
    {
      name: "Office Visit Report",
      imageUrl: reportImg,
      pageUrl: "/office/VisitorReport",
      isHoverCard: false,
    },
    {
      name: "Visit to Factory",
      imageUrl: factoryImg,
      pageUrl: "/factory/CreateGatePass",
      isHoverCard: false,
    },
    {
      name: "Factory Visit Report",
      imageUrl: reportImg,
      pageUrl: "/factory/ReceptionReport",
      isHoverCard: false,
    },
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  //// handle MouseHover effect code
  const handleMouseEnter = (index) => {
    const updatedData = visitorCardData.map((item, i) =>
      i === index ? { ...item, isHoverCard: true } : item
    );
    setVisitorCardData(updatedData);
  };

  ///// mouse leave effect code
  const handleMouseLeave = (index) => {
    const updatedData = visitorCardData.map((item, i) =>
      i === index ? { ...item, isHoverCard: false } : item
    );
    setVisitorCardData(updatedData);
  };

  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              {visitorCardData.map((item, i) => {
                return (
                  <div className="col-xl-3 col-lg-6 col-12" key={i}>
                    <div
                      className={`card card-hover cur_point ${
                        item.isHoverCard ? "hovered" : ""
                      }`}
                      onClick={() => navigate(item.pageUrl)}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={() => handleMouseLeave(i)}
                    >
                      <div className="card-content">
                        <div className="card-body d-flex justify-content-between card_text">
                          <img src={item.imageUrl} alt="img" />
                          <h4 className="text-info pt-15">{item.name}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VisitorCard;
