import React from "react";
import { Modal, Button } from "antd";

//// delete confirmation modal code
const DeleteConfirmationModal = ({ visible, onDelete, onCancel }) => {
  return (
    <Modal
      className="delete_modal"
      title="Delete"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel} className="btn_cancel_mdl">
          Cancel
        </Button>,
        <Button onClick={onDelete} className="btn_delete_mdl">
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this record?</p>
    </Modal>
  );
};

export default DeleteConfirmationModal;
