import React, { Fragment, useState, useEffect } from "react";
import { Table } from "antd";
import formatNumber from "../../../../utils/formatNumber";

const InvoiceDetailsTbl = ({
  invoiceDetailsData,
  isLoading,
  OnhandleTableSortColumn,
  isEmployee,
}) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (invoiceDetailsData?.customerInvoiceList?.length > 0) {
      setTotalRecords(invoiceDetailsData.totalRecords);
      setPageNo(Number(invoiceDetailsData.currentPage));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [invoiceDetailsData.customerInvoiceList]);

  //// handle Table Column sorting and pagination code
  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      currentPage: pagination.current,
      table: "Invoice Details",
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
    });
  };

  const data = invoiceDetailsData?.customerInvoiceList || [];

  const transformedData = [];
  data.forEach((customerData) => {
    const totalInvoices = customerData.customerAgeingList.length;

    customerData.customerAgeingList.forEach((invoice, index) => {
      transformedData.push({
        key: customerData.customerOrganization,
        customerOrganization:
          index === 0 ? customerData.customerOrganization : null, // Show customer name only for the first row
        rowSpan: index === 0 ? totalInvoices + 1 : 0, // Set rowSpan for the first row
        invoiceDate: invoice.invoiceDate,
        invoiceNo: invoice.invoiceNo,
        totalPendingAmount_LCY: invoice.totalPendingAmount_LCY,
        totalInvoiceAmt: invoice.totalInvoiceAmt,
        totalundamt: invoice.totalundamt,
        totalunadjamt: invoice.totalunadjamt,
        totalPendingAmount: invoice.totalPendingAmount,
        totalcredit: invoice.totalcredit,
        totalcreditdays: invoice.totalcreditdays,
        totaloverdued: invoice.totaloverdued,
        totalTodaytot: invoice.totalTodaytot,
        totalfirst: invoice.totalfirst,
        totalsecond: invoice.totalsecond,
        totalthird: invoice.totalthird,
        totalfourth: invoice.totalfourth,
        totalfifth: invoice.totalfifth,
        totalsixth: invoice.totalsixth,
        totalseventh: invoice.totalseventh,
        totaleighth: invoice.totaleighth,
        currency: invoice.currency,
        duedt: invoice.duedt,
      });
    });

    transformedData.push({
      key: `${customerData.customerOrganization}-total`,
      customerOrganization: null,
      rowSpan: 0,
      invoiceDate: "Total",
      invoiceNo: null,
      totalPendingAmount_LCY: customerData.totalPendingAmount_LCY,
      totalInvoiceAmt: customerData.totalInvoiceAmt,
      totalundamt: customerData.totalundamt,
      totalunadjamt: customerData.totalunadjamt,
      totalPendingAmount: customerData.totalPendingAmount,
      totalcredit: customerData.totalcredit,
      totalcreditdays: customerData.totalcreditdays,
      totaloverdued: customerData.totaloverdued,
      totalTodaytot: customerData.totaltodaytot,
      totalfirst: customerData.totalfirst,
      totalsecond: customerData.totalsecond,
      totalthird: customerData.totalthird,
      totalfourth: customerData.totalfourth,
      totalfifth: customerData.totalfifth,
      totalsixth: customerData.totalsixth,
      totalseventh: customerData.totalseventh,
      totaleighth: customerData.totaleighth,
    });
  });

  const columns = [
    {
      title: "Customer",
      dataIndex: "customerOrganization",
      key: "customerOrganization",
      fixed: "left",
      background: "white",
      width: 230,
      position: "sticky",
      render: (text, record) => {
        return {
          children: text ? <strong>{text}</strong> : null,
        };
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 140,
      background: "#f5f5f5",
      fixed: "left",
      render: (text) => (text === "Total" ? <strong>{text}</strong> : text),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 150,
      fixed: "left",
    },
    {
      title: "Due Date",
      dataIndex: "duedt",
      key: "invoiceNo",
      width: 120,
      fixed: "left",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "invoiceNo",
      width: 100,
      fixed: "left",
    },
    ...(isEmployee
      ? [
          {
            title: isEmployee ? "Pending Amount LCY" : "Pending Amount",
            dataIndex: "totalPendingAmount_LCY",
            key: "totalPendingAmount_LCY",
            width: 200,
            // sorter: (a, b) =>
            //   parseFloat((a.totalPendingAmount_LCY || "0").replace(/,/g, "")) -
            //   parseFloat((b.totalPendingAmount_LCY || "0").replace(/,/g, "")),
            // showSorterTooltip: false,
            render: (text, record) =>
              `${formatNumber(
                parseFloat(
                  (record.totalPendingAmount_LCY || "0").replace(/,/g, "")
                )
              )}`,
          },
        ]
      : []),

    {
      title: "Invoice Amount",
      dataIndex: "totalInvoiceAmt",
      key: "totalInvoiceAmt",
      width: 150,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalInvoiceAmt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Undue Amount",
      dataIndex: "totalundamt",
      key: "totalundamt",
      width: 150,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalundamt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Unadjusted Amount",
      dataIndex: "totalunadjamt",
      key: "totalunadjamt",
      width: 200,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalunadjamt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Pending Amount",
      dataIndex: "totalPendingAmount",
      key: "totalPendingAmount",
      width: 150,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalPendingAmount || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Total Credit Limit",
      dataIndex: "totalcredit",
      key: "totalcredit",
      width: 200,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalcredit || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Credit Days",
      dataIndex: "totalcreditdays",
      key: "totalcreditdays",
      width: 150,
      render: (_i, row) => {
        return <div>{row.totalcreditdays}</div>;
      },
    },
    {
      title: "Over Due Days",
      dataIndex: "totaloverdued",
      key: "totaloverdued",
      width: 180,
      render: (_i, row) => {
        return <div>{row.totaloverdued}</div>;
      },
    },
    {
      title: "Today Total Days",
      dataIndex: "totalTodaytot",
      key: "totalTodaytot",
      width: 200,
      render: (_i, row) => {
        return <div>{row.totalTodaytot}</div>;
      },
    },
    {
      title: "0-10 Days",
      dataIndex: "totalfirst",
      key: "totalfirst",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalfirst !== ""
              ? formatNumber(
                  parseFloat((row.totalfirst || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "11-15 Days",
      dataIndex: "totalsecond",
      key: "totalsecond",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalsecond !== ""
              ? formatNumber(
                  parseFloat((row.totalsecond || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "16-30 Days",
      dataIndex: "totalthird",
      key: "totalthird",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalthird !== ""
              ? formatNumber(
                  parseFloat((row.totalthird || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "31-45 Days",
      dataIndex: "totalfourth",
      key: "totalfourth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalfourth !== ""
              ? formatNumber(
                  parseFloat((row.totalfourth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "46-60 Days",
      dataIndex: "totalfifth",
      key: "totalfifth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalfifth !== ""
              ? formatNumber(
                  parseFloat((row.totalfifth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "61-75 Days",
      dataIndex: "totalsixth",
      key: "totalsixth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalsixth !== ""
              ? formatNumber(
                  parseFloat((row.totalsixth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "76-90 Days",
      dataIndex: "totalseventh",
      key: "totalseventh",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalseventh !== ""
              ? formatNumber(
                  parseFloat((row.totalseventh || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "90 Days",
      dataIndex: "totaleighth",
      key: "totaleighth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totaleighth !== ""
              ? formatNumber(
                  parseFloat((row.totaleighth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables payment_table"
        dataSource={transformedData}
        loading={isLoading}
        rowKey="uniqueKey"
        columns={columns}
        onChange={handleTableAscDes}
        rowClassName={(record) =>
          record.invoiceDate === "Total" ? "total-row" : ""
        }
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 100,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        summary={() => {
          return (
            <Table.Summary.Row
              className="tbl_summary"
              style={{ position: "relative" }}
            >
              <Table.Summary.Cell
                colSpan={1}
                index={0}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={4}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              {isEmployee && (
                <Table.Summary.Cell>
                  {formatNumber(invoiceDetailsData.total)}
                </Table.Summary.Cell>
              )}
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalInvoiceamtTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalUndamtTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalUnadjamtTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalPendingAmount)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalcreditTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalCreditdaysTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalOverDuedaysTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.totalTodaytotTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.firstTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.secondTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.thirdTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.fourthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.fifthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.sixthTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.seventhTotal)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(invoiceDetailsData.eighthTotal)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Fragment>
  );
};

export default InvoiceDetailsTbl;
