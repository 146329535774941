import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  LogDeliveredDis,
  LogTransitDis,
  LogPendingDis,
  LogTotalDis,
} from "../imageAssets.js";
import { Card, Tabs, Button, Spin, Input } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import { NotificationManager } from "react-notifications";
import {
  setTableSearchInput,
  setTableSortingData,
} from "../../../actions/tableListing/tableSortingActionData";
import { checkLogisticActiveTabNo } from "../../../helper/commonData";
import { useNavigate } from "react-router-dom";
import LogisticAllTab from "../../../components/LogisticTabs/logisticAllTab.js";
import LogisticTransitTab from "../../../components/LogisticTabs/logisticTransit";
import LogisticPendingTab from "../../../components/LogisticTabs/logisticPendingDis";
import LogisticCustomerDelivery from "../../../components/LogisticTabs/logisticCustomerDelivery";
import LogisticDeliveredTab from "../../../components/LogisticTabs/logisticDelivered";
import LogisticPendingBLTab from "../../../components/LogisticTabs/logisticPendingBL";
import logisticDashboardService from "../../../services/Logitstics/dashboard-service.js";
import { PayloadForApplyFilterData } from "../../../components/PayloadForApplyFilterData.js";

const { Search } = Input;
const { TabPane } = Tabs;

const LogisticDashboard = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const tableSortingData = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const tableSearchInput = useSelector(
    (state) => state.TableSortingData.tableSearchInput
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isCardsLoading, setIsCardsLoading] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabTableData, setTabTableData] = useState({});
  const [activeTabs, setActiveTabs] = useState("");
  const [isEmployee, setIsEmployee] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [logisticTileCounts, setLogisticTileCounts] = useState({});
  const [tableSearchData, setTableSearchData] = useState(tableSearchInput);

  useEffect(() => {
    if (tableSortingData === null) {
      dispatch(
        setTableSortingData({
          currentPage: 1,
          orderBy: "desc",
          sortColumn: "",
        })
      );
    }

    if (userDetails?.isEmployee !== undefined) {
      setIsEmployee(userDetails.isEmployee);
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (
      companyDetails ||
      Object.keys(applyFilterObj).length > 0 ||
      tableSortingData?.sortColumn
    ) {
      handleGetDashboardCount();
      handleGetTabsDashboardData();
    }
  }, [
    tableSortingData,
    dispatch,
    userDetails,
    applyFilterObj,
    activeTabs,
    companyDetails,
  ]);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      if (tableSearchData) {
        handleGetTabsDashboardData();
      }
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [tableSearchData]);

  useEffect(() => {
    const tabKey = checkLogisticActiveTabNo(type);
    setActiveTabs(tabKey);
  }, [type]);

  //// API calling Function code Start
  /// handle fetch logistic tile counts
  const handleGetDashboardCount = () => {
    setIsCardsLoading(true);
    let inputData = {
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };

    if (isGlobalFilter) {
      showFilterDrawer();
    }
    logisticDashboardService
      .getLogisticTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setIsCardsLoading(false);
          setLogisticTileCounts(objData);
        } else {
          NotificationManager.error(response.data.message);
          setLogisticTileCounts({});
          setIsCardsLoading(false);
        }
      })
      .catch((error) => {
        setIsCardsLoading(false);
        console.log(error, "Error");
      });
  };

  //// handle get tab wise table listing Api
  const handleGetTabsDashboardData = async () => {
    let activeTabName = "";
    switch (activeTabs) {
      case "1":
        activeTabName = "all";
        break;
      case "2":
        activeTabName = "Pending Dispatch";
        break;
      case "3":
        activeTabName = "Pending BL";
        break;
      case "4":
        activeTabName = "Intransit";
        break;
      case "5":
        activeTabName = "Customer Delivery Pending";
        break;
      case "6":
        activeTabName = "Delivered";
        break;

      default:
        activeTabName = "all";
        break;
    }
    setIsLoading(true);
    let inputData = {
      pageindex: tableSortingData !== null ? tableSortingData?.currentPage : 1,
      pagesize: 10,
      sortcolumn:
        tableSortingData && tableSortingData.sortColumn !== ""
          ? tableSortingData.sortColumn
          : "SAP_Order_Number",
      orderBy: tableSortingData !== null ? tableSortingData?.orderBy : "desc",
      tabName: activeTabName,
      search: tableSearchData === null ? "" : tableSearchData?.trim(),
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    await logisticDashboardService
      .getLogisticDashboardTableListingAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          setIsLoading(false);
          if (finalResponse.totalRecords !== "0") {
            setTabTableData(finalResponse);
          } else {
            setIsLoading(false);
            setTabTableData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
        setIsLoading(false);
      });
  };

  //// API calling Function code End
  /// handle Table search input code
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    dispatch(setTableSearchInput(value));
    let tempTableObjColumn = {
      currentPage: 1,
      orderBy: "desc",
      sortColumn: "",
    };
    handleGetTabsDashboardData(value, tempTableObjColumn);
  };

  /// handle Table search input onchange code
  const handleTblSearchChange = (e) => {
    const { value } = e.target;
    setTableSearchData(value);
    dispatch(setTableSearchInput(value));
  };

  /// handle tab onchange code
  const handleTabChange = (key) => {
    let tabName = "";
    setTableSearchData("");
    switch (key) {
      case "1":
        tabName = "all";
        dispatch(
          setTableSortingData({
            sortColumn: "SAP_Order_Number",
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "2":
        tabName = "Pending Dispatch";
        dispatch(
          setTableSortingData({
            sortColumn: "SAP_Order_Number",
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "3":
        tabName = "Pending BL";
        dispatch(
          setTableSortingData({
            sortColumn: "SAP_Order_Number",
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "4":
        tabName = "Intransit";
        dispatch(
          setTableSortingData({
            sortColumn: "SAP_Order_Number",
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "5":
        tabName = "Customer Delivery Pending";
        dispatch(
          setTableSortingData({
            sortColumn: "SAP_Order_Number",
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "6":
        tabName = "Delivered";
        dispatch(
          setTableSortingData({
            sortColumn: "SAP_Order_Number",
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      default:
        tabName = "";
        break;
    }
    navigate(`/logistic/${tabName}`);
    setActiveTabs(key);
  };

  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };

  const handleTableSortColumn = (data) => {
    dispatch(
      setTableSortingData({
        sortColumn: data.sortColumn ? data.sortColumn : "customer_PO_Date",
        orderBy: data.orderBy,
        currentPage: data.currentPage,
      })
    );
  };
  //// handle filter data exist code
  function hasFilterDataExist(obj) {
    return Object.values(obj).some((array) => array.length > 0);
  }
  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            {hasFilterDataExist(applyFilterObj) && (
              <h5 className="appled_filter">Applied Filters</h5>
            )}
            <h5 className="content-header-title d-inline-block">Logistics</h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>
            <Spin size="large" spinning={isCardsLoading}>
              <div className="row">
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card bg_info">
                    <div className="card-content">
                      <div className="card-body logisticTotal images_bg">
                        <h4 className="text-white font24">
                          <img
                            src={LogTotalDis}
                            className="card_small_img"
                            alt="Img"
                            style={{ width: "17px" }}
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "18px", zIndex: 3 }}>
                              Total Dispatch
                            </span>
                            <label>
                              {Object.keys(logisticTileCounts).length > 0 &&
                                logisticTileCounts.totalDispatchedOrdersCount}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card bg_warning">
                    <div className="card-content">
                      <div className="card-body logisticPending images_bg">
                        <h4 className="text-white font24">
                          <img
                            src={LogPendingDis}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "18px", zIndex: 3 }}>
                              Pending Dispatch
                            </span>
                            <label>
                              {Object.keys(logisticTileCounts).length > 0 &&
                                logisticTileCounts.pendingDispatchQtyCount}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card bg_danger">
                    <div className="card-content">
                      <div className="card-body logisticTransit images_bg ">
                        <h4
                          className="text-white font24"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src={LogTransitDis}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "18px", zIndex: 3 }}>
                              In-Transit Dispatch
                            </span>
                            <label>
                              {Object.keys(logisticTileCounts).length > 0 &&
                                logisticTileCounts.transitOrdersCount}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3 col-lg-6 col-12"}>
                  <div className="card  bg_success">
                    <div className="card-content">
                      <div className="card-body logisticDelivered images_bg">
                        <h4 className="text-white font24">
                          <img
                            src={LogDeliveredDis}
                            className="card_small_img"
                            alt="Img"
                            style={{ width: "17px" }}
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "18px", zIndex: 3 }}>
                              Delivered
                            </span>
                            <label>
                              {Object.keys(logisticTileCounts).length > 0 &&
                                logisticTileCounts.deliveredQtyCount}
                            </label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <Card>
              <div className="row dashboard_tabs">
                <Tabs
                  activeKey={activeTabs}
                  className="dash_tab_panel"
                  type="card"
                  tabBarExtraContent={
                    <div className="tab_searchtxt">
                      <Search
                        placeholder="Search..."
                        className="dash_search"
                        allowClear
                        onSearch={() => handleOnSearchData()}
                        size="large"
                        value={tableSearchData}
                        onChange={handleTblSearchChange}
                      />
                    </div>
                  }
                  onChange={handleTabChange}
                >
                  <TabPane tab="All" key="1">
                    <LogisticAllTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="Pending Dispatch" key="2">
                    <LogisticPendingTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="Pending BL" key="3">
                    <LogisticPendingBLTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="In-Transit" key="4">
                    <LogisticTransitTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="Customer Delivery Pending" key="5">
                    <LogisticCustomerDelivery
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="Delivered" key="6">
                    <LogisticDeliveredTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default LogisticDashboard;
