import React, { useEffect, useState } from "react";
import { Card, Button, Input } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import GlobalFilterDrawer from "../../components/GlobalFilterDrawer";
import VisitorReportTable from "./Tables/visitorReportTable";
import moment from "moment";
import { exportFileImg } from "../Elite/imageAssets";

const { Search } = Input;

const VisitorReport = () => {
  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetails?.userDetails || {}
  );

  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [visitorTableData, setVisitorTableData] = useState({});
  const [tableObjColumn, setTableObjColumn] = useState({
    sortColumn: "",
    orderBy: "",
    currentPage: 1, // Initialized as number
  });
  const [tableSearchData, setTableSearchData] = useState("");

  useEffect(() => {
    fetchVisitorTableData();
  }, [tableObjColumn]);

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee || false);
  }, [userDetails]);
  //// handle Apply filter code
  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  //// Filter Toggle Drawer code
  const toggleFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter((prevIsGlobalFilter) => !prevIsGlobalFilter);
  };
  //// filter Data exist code
  const hasFilterDataExist = (obj) =>
    Object.values(obj).some(
      (array) => Array.isArray(array) && array.length > 0
    );
  //// table column sorting and pagination code
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      sortColumn: data.sortColumn,
      orderBy: data.orderBy,
      currentPage: data.currentPage,
    });
  };
  //// table input search code
  const handleTblSearchChange = (e) => {
    setTableSearchData(e.target.value);
  };
  //// onSearch data code
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    fetchVisitorTableData();
  };

  /// Fetch Table Data API
  const fetchVisitorTableData = () => {
    setIsTableLoading(true);

    const inputData = {
      pageindex:
        tableObjColumn.currentPage !== "" ? tableObjColumn?.currentPage : 1,
      pagesize: 10,
      sortcolumn: tableObjColumn !== "" ? tableObjColumn?.sortColumn : "",
      orderBy: tableObjColumn.orderBy !== "" ? tableObjColumn?.orderBy : "desc",
      search: tableSearchData,
      isReport: true,
    };

    visitorManagementService
      .getVisitorManagementTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const finalData = response.data.responseData;
          setVisitorTableData(finalData || {});
        } else {
          NotificationManager.error(
            response.data.message || "Failed to fetch data."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching visitor table data:", error);
        NotificationManager.error("An error occurred while fetching data.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  /// Export Data API
  const handleExportVisitorData = () => {
    setIsTableLoading(true);
    const currentDate = moment().format("YYYY-MM-DD");

    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: tableObjColumn.sortColumn,
      orderBy: tableObjColumn.orderBy,
      search: tableSearchData,
      isReport: true,
    };

    visitorManagementService
      .exportVisitorListAPI(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Visitor_Report_${currentDate}.xlsx`);

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);

        NotificationManager.success("Excel export successful!");
      })
      .catch((error) => {
        console.error("Error exporting visitor data:", error);
        NotificationManager.error("Excel export failed.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  return (
    <div>
      <div className="app-content content visitorPass">
        <div className="content-wrapper">
          <div className="content-body">
            {hasFilterDataExist(applyFilterObj) && (
              <h5 className="applied_filter">Applied Filters</h5>
            )}
            <h5 className="content-header-title d-inline-block">
              Visitor Report
            </h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={toggleFilterDrawer}
              />
            </div>

            <div className="gate_pass_section">
              <div className="row payment_tab">
                <label className="analy_type">Visitor Data</label>
              </div>

              <div className="d-flex">
                <div className="tab_searchtxt pb_0">
                  <Search
                    placeholder="Search..."
                    className="dash_search"
                    allowClear
                    onSearch={() => handleOnSearchData()}
                    size="large"
                    value={tableSearchData}
                    onChange={handleTblSearchChange}
                  />
                </div>

                <div className="export_lbl">
                  <label onClick={handleExportVisitorData}>
                    Export
                    <img src={exportFileImg} alt="Export icon" />
                  </label>
                </div>
              </div>
            </div>

            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <VisitorReportTable
                    visitorTableData={visitorTableData}
                    visitorLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={toggleFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </div>
  );
};

export default VisitorReport;
