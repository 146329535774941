import React, { Fragment, useEffect, useState } from "react";
import { editIcon } from "../../Elite/imageAssets";
import { Table, TimePicker } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import visitorManagementService from "../../../services/VisitorManagement/visitorManagement-service";
import { NotificationManager } from "react-notifications";

const SecurityReportTable = ({
  securityReportData,
  gatePassLoading,
  OnhandleTableSortColumn,
  // isEmployee,
}) => {
  const handleTableChange = (pagination, filters, sorter) => {
    OnhandleTableSortColumn(pagination, filters, sorter);
  };
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [editingRow, setEditingRow] = useState(null);
  const [updatedRecords, setUpdatedRecords] = useState([]);
  const tableSorting = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  useEffect(() => {
    if (securityReportData?.gatePassRecords) {
      setUpdatedRecords([...securityReportData.gatePassRecords]);
      setTotalRecords(securityReportData.totalRecords || 0);
      setPageNo(Number(securityReportData.currentPage));
    }
  }, [securityReportData]);

  // Handle time change
  const handleTimeChange = (time, timeString, field, record) => {
    const updatedData = updatedRecords.map((item) => {
      if (item.id === record.id) {
        return { ...item, [field]: timeString };
      }
      return item;
    });
    setUpdatedRecords(updatedData);
  };

  // Handle edit and save actions
  const handleEditClick = (record) => {
    if (editingRow === record.id) {
      setEditingRow(null);
    } else {
      setEditingRow(record.id);
    }
  };
  //// API called for save data
  const handleSaveClick = (record) => {
    const updatedRecord = updatedRecords.find((item) => item.id === record.id);
    const inputData = {
      id: updatedRecord.id,
      inTime: updatedRecord.inTime || "",
      outTime: updatedRecord.outTime || "",
    };

    visitorManagementService
      .postUpdateTime(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const successMessage =
            response.data.responseData || "Data updated successfully!";
          if (
            successMessage.includes(
              "Record can be updated for the current date only"
            )
          ) {
            NotificationManager.warning(successMessage);
            setEditingRow(null);
          } else {
            NotificationManager.success(successMessage);

            setUpdatedRecords((prevRecords) =>
              prevRecords.map((item) =>
                item.id === record.id
                  ? { ...item, vehicleId: inputData.vehicleId }
                  : item
              )
            );

            setEditingRow(null);
          }
        } else {
          NotificationManager.error(
            response.data.responseData || "Update failed."
          );
        }
      })
      .catch((error) => {
        console.error("Error updating record:", error);
      });
  };

  const tableColumns = [
    {
      title: "Sr No",
      dataIndex: "",
      sorter: false,
      showSorterTooltip: false,
      width: 80,
      render: (_i, row, index) => {
        return (
          <div>
            <label>{index + 1}</label>
          </div>
        );
      },
    },
    {
      title: "People",
      dataIndex: "numberOfPeople",
      sorter: (a, b) => a.numberOfPeople - b.numberOfPeople,
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => <label>{row.date}</label>,
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      width: 150,
      sorter: (a, b) => a.inTime - b.inTime,
      showSorterTooltip: false,

      render: (_i, row) => {
        const record = updatedRecords.find((item) => item.id === row.id) || row;
        return editingRow === row.id ? (
          <TimePicker
            format="h:mm A"
            showNow={false}
            value={record.inTime ? moment(record.inTime, "h:mm A") : null}
            onChange={(time, timeString) =>
              handleTimeChange(time, timeString, "inTime", row)
            }
            use12Hours
          />
        ) : (
          <label>{record.inTime}</label>
        );
      },
    },
    {
      title: "Out Time",
      dataIndex: "outTime",
      width: 150,
      sorter: (a, b) => a.outTime - b.outTime,
      showSorterTooltip: false,

      render: (_i, row) => {
        const record = updatedRecords.find((item) => item.id === row.id) || row;
        return editingRow === row.id ? (
          <TimePicker
            format="h:mm A"
            showNow={false}
            value={record.outTime ? moment(record.outTime, "h:mm A") : null}
            onChange={(time, timeString) =>
              handleTimeChange(time, timeString, "outTime", row)
            }
            use12Hours
          />
        ) : (
          <label>{record.outTime}</label>
        );
      },
    },
    {
      title: "Lunch",
      dataIndex: "lunch",
      width: 120,
      sorter: (a, b) => a.lunch - b.lunch,
      showSorterTooltip: false,
      render: (_i, row) => <label>{row.lunch}</label>,
    },
    {
      title: "Transport",
      dataIndex: "transport",
      width: 160,
      sorter: (a, b) => a.transport - b.transport,
      showSorterTooltip: false,
      render: (_i, row) => <label>{row.transport}</label>,
    },
    {
      title: "Car Number",
      dataIndex: "carNumber",
      width: 180,
      sorter: (a, b) => a.carNumber - b.carNumber,
      showSorterTooltip: false,
      render: (_i, row) => <label>{row.carNumber}</label>,
    },
    {
      title: "Vehicle Name",
      dataIndex: "vehicleName",
      width: 180,
      sorter: (a, b) => a.vehicleName - b.vehicleName,
      showSorterTooltip: false,
      render: (_i, row) => {
        const record = updatedRecords.find((item) => item.id === row.id) || row;
        return <label>{record.vehicleName || ""}</label>;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: 200,
      render: (_i, row) => <label>{row.remarks}</label>,
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 80,

      render: (_text, _record) => (
        <div className="table_action_img">
          <div
            onClick={() =>
              editingRow === _record.id
                ? handleSaveClick(_record)
                : handleEditClick(_record)
            }
            style={{ cursor: "pointer" }}
          >
            {editingRow === _record.id ? (
              <span style={{ color: "#3cafda" }}>Save</span>
            ) : (
              <img src={editIcon} alt="Edit" />
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="gatePassTbl payment_table"
        columns={tableColumns}
        dataSource={securityReportData.gatePassRecords}
        loading={gatePassLoading}
        pagination={{
          total: totalRecords,
          current: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        onChange={handleTableChange}
      />
    </Fragment>
  );
};

export default SecurityReportTable;
