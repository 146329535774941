import React from "react";
import Chart from "react-apexcharts";
import { Spin } from "antd";

const InvoiceOutstandingBarChart = ({
  invoiceOutStandingData,
  customerOutstandingAvg,
  isOutStandingLoading,
  isEmployee,
}) => {
  // Extract the month names for x-axis categories
  const months = invoiceOutStandingData.map((item) => item.month);

  // Extract the data for the Listing
  const invoiceData = invoiceOutStandingData.map((item) =>
    parseFloat(item.totalInvoice)
  );
  const collectionData = invoiceOutStandingData.map((item) =>
    parseFloat(item.totalCollection)
  );
  const outstandingData = invoiceOutStandingData.map((item) =>
    parseFloat(item.totalOutstanding)
  );

  const options = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    xaxis: {
      categories: months,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
      },
    },
    annotations: {
      yaxis: [
        {
          y: customerOutstandingAvg.avgTotalCollection,
          borderColor: "red",
          label: {
            borderColor: "red",
            style: {
              color: "#fff",
              background: "red",
            },
          },
        },
        {
          y: customerOutstandingAvg.avgTotalOutstanding,
          borderColor: "blue",
          label: {
            borderColor: "blue",
            style: {
              color: "#fff",
              background: "blue",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontSize: "12px",
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      markers: {
        width: 50,
        height: 8,
      },
    },
    colors: ["#87C5FC", "#1FE397", "#ACACAC"],
    tooltip: {
      style: {
        fontSize: "12px",
        color: "#000",
      },
      theme: "light",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const month = w.globals.labels[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];

        return `<div class="payment_custom_tooltip">
                  <span style="color: #ffff; font-weight: bold;">${month}</span><br/>
                  <span>${w.globals.seriesNames[seriesIndex]}: ${value}</span>
                </div>`;
      },
    },
  };

  const series = [
    {
      name: "Invoice",
      data: invoiceData,
    },
    {
      name: isEmployee ? "Collection" : "Payment",
      data: collectionData,
    },
    {
      name: "O/S",
      data: outstandingData,
    },
  ];

  return (
    <div>
      <Spin size="large" spinning={isOutStandingLoading}>
        <Chart options={options} series={series} type="bar" height={350} />
      </Spin>
    </div>
  );
};

export default InvoiceOutstandingBarChart;
