import React, { Fragment, useState, useEffect } from "react";
import { imgCubes, visitorImg } from "./imageAssets";
import { useNavigate } from "react-router-dom";

const EliteCard = () => {
  const navigate = useNavigate();
  const [isEliteHovered, setIsEliteHovered] = useState(false);
  // const [isVisitorHovered, setIsVisitorHovered] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //// handle card onclick
  const handleCardClick = (name) => {
    if (name === "elite") {
      navigate("/eliteDashboard");
    } else {
      navigate("/visitorHome");
    }
  };

  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isEliteHovered ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("elite")}
                  onMouseEnter={() => setIsEliteHovered(true)}
                  onMouseLeave={() => setIsEliteHovered(false)}
                >
                  <div className="card-content">
                    <div className="card-body d-flex justify-content-between">
                      <img src={imgCubes} alt="img" />
                      <h4 className="text-info pt-15">Elite</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isVisitorHovered ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("visitor")}
                  onMouseEnter={() => setIsVisitorHovered(true)}
                  onMouseLeave={() => setIsVisitorHovered(false)}
                >
                  <div className="card-content">
                    <div className="card-body d-flex justify-content-between">
                      <img src={visitorImg} alt="img" />
                      <h4 className="text-info pt-15">Visitor</h4>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EliteCard;
