import React, { Fragment, useEffect, useState } from "react";
import {
  imgProduction,
  imgTransit,
  imgDelivered,
  imgOrder,
} from "../../pages/Elite/imageAssets";
import { Table, Spin } from "antd";
import { NotificationManager } from "react-notifications";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import overTimeScheduleService from "../../services/OverTimeSchedule-tabs-service";
import { formatDate, getColorCode } from "../../helper/commonData";

const OverviewTabDetails = ({
  name,
  labelId,
  tabType,
  dropdownValue,
  SapOrderNumber,
}) => {
  const isO2WPage =
    window.location.pathname.split("/")[1] === "o2w" ? true : false;

  const [overviewCountsDetails, setOverviewCountsDetails] = useState({});
  const [deliveryPerInvoicesData, setDeliveryPerInvoicesData] = useState([]);
  const [perInvoicesData, setPerInvoicesData] = useState([]);
  const [perInvoicesColors, setPerInvoicesColors] = useState([]);
  const [tentativeDayWiseSeries2, setTentativeDayWiseSeries2] = useState([]);
  const [actualDayWiseSeries1, setActualDayWiseSeries1] = useState([]);
  const [timelineSeries1, setTimelineSeries1] = useState([]);
  const [timelineSeries2, setTimelineSeries2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOverviewGetOrderandInvoiceData();
    getOverviewDayandTimelineChartData();
  }, [labelId, tabType, dropdownValue]);

  //// handle Get Overview Order with Invoice data APi called
  const getOverviewGetOrderandInvoiceData = async () => {
    setIsLoading(true);
    let inputData = {
      dropdownValue: dropdownValue === "all" ? "" : dropdownValue,
      labelValue: labelId,
      flag: tabType,
      SapOrderNumber,
    };
    await overTimeScheduleService
      .overviewGetOrderandInvoiceDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData;
          setOverviewCountsDetails(finalData.orderStatusPercentages[0]);
          setDeliveryPerInvoicesData(finalData.invoiceStatusPercentages);
          let tempInvoiceData = finalData.invoiceStatusPercentages.map(
            (item, index) => {
              return {
                name: `${item.invoice_Number}-${index + 1}`,
                data: [Number(item.sale_Quantity_Percentage)],
              };
            }
          );
          let tempInvoicesColors = finalData.invoiceStatusPercentages.map(
            (item) => getColorCode(item.delay_Color_Code)
          );
          setPerInvoicesData(tempInvoiceData);
          setPerInvoicesColors(tempInvoicesColors);
          setIsLoading(false);
        } else {
          setOverviewCountsDetails({});
          setDeliveryPerInvoicesData([]);
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  //// handle Get Overview and timeline Chart data
  const getOverviewDayandTimelineChartData = async () => {
    setIsLoading(true);
    let inputData = {
      dropdownValue,
      labelValue: labelId,
      flag: tabType,
      SapOrderNumber,
    };
    await overTimeScheduleService
      .overviewGetDayandTimelineChartDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData;
          let dayWiseData = finalData.delayAnalyses;
          let timeLineData = finalData.timelineCharts;
          let tempDaysSeries1 = [
            ...(isO2WPage
              ? [
                  Number(dayWiseData[0]["order_Delay_Estimated"]),
                  Number(dayWiseData[0]["shipment_Booking_Estimated"]),
                ]
              : []),
            Number(dayWiseData[0]["dispatch_Estimated"]),
            Number(dayWiseData[0]["bL_Estimated"]),
            Number(dayWiseData[0]["etD_Estimated"]),
            Number(dayWiseData[0]["etA_Estimated"]),
            Number(dayWiseData[0]["contingency_Days_Estimated"]),
          ];

          setTentativeDayWiseSeries2(tempDaysSeries1);

          let tempDaysSeries2 = [
            ...(isO2WPage
              ? [
                  Number(dayWiseData[0]["order_Delay_Actual"]),
                  Number(dayWiseData[0]["shipment_Booking_Actual"]),
                ]
              : []),
            Number(dayWiseData[0]["dispatch_Actual"]),
            Number(dayWiseData[0]["bL_Actual"]),
            Number(dayWiseData[0]["etD_Actual"]),
            Number(dayWiseData[0]["etA_Actual"]),
            Number(dayWiseData[0]["contingency_Days_Actual"]),
          ];

          setActualDayWiseSeries1(tempDaysSeries2);

          let estimatedTimlineSeries1 = [
            ...(isO2WPage
              ? [
                  {
                    x: "Order Date",
                    y: formatDate(timeLineData[0].knack_Schedule_SO_Date),
                  },
                  {
                    x: "Shipment Booking Date",
                    y: formatDate(timeLineData[0].shipment_Booking_Estimated),
                  },
                ]
              : []),
            {
              x: "Dispatch Date",
              y: formatDate(timeLineData[0].dispatch_Estimated),
            },
            { x: "BL Date", y: formatDate(timeLineData[0].bL_Doc_Estimated) },
            {
              x: "ETD",
              y: formatDate(timeLineData[0].estimated_Date_Estimated),
            },
            {
              x: "ETA Port",
              y: formatDate(timeLineData[0].etA_Port_Estimated),
            },
            {
              x: "Delivery Date",
              y: formatDate(timeLineData[0].delivery_Date_Estimated),
            },
          ];
          setTimelineSeries1(estimatedTimlineSeries1);
          let actualTimlineSeries2 = [
            ...(isO2WPage
              ? [
                  {
                    x: `Order Date - ${Number(
                      dayWiseData[0]["order_Delay_Estimated"]
                    )}`,
                    y: formatDate(timeLineData[0].order_Customer_PO_Date),
                  },
                  {
                    x: `Shipment Booking Date - ${Number(
                      dayWiseData[0]["shipment_Booking_Estimated"]
                    )}`,
                    y: formatDate(timeLineData[0].shipment_Booking_Actual),
                  },
                ]
              : []),
            {
              x: `Dispatch Date - ${Number(
                dayWiseData[0]["dispatch_Estimated"]
              )}`,
              y: formatDate(timeLineData[0].dispatch_Actual),
            },
            {
              x: `BL Date - ${Number(dayWiseData[0]["bL_Estimated"])}`,
              y: formatDate(timeLineData[0].bL_Doc_Actual),
            },
            {
              x: `ETD - ${Number(dayWiseData[0]["etD_Estimated"])}`,
              y: formatDate(timeLineData[0].estimated_Date_Actual),
            },
            {
              x: `ETA Port - ${Number(dayWiseData[0]["etA_Estimated"])}`,
              y: formatDate(timeLineData[0].etA_Port_Actual),
            },
            {
              x: `Delivery Date - ${Number(
                dayWiseData[0]["delivery_Days_Estimated"]
              )}`,
              y: formatDate(timeLineData[0].delivery_Date_Actual),
            },
          ];
          setTimelineSeries2(actualTimlineSeries2);

          setIsLoading(false);
        } else {
          setTentativeDayWiseSeries2([]);
          setActualDayWiseSeries1([]);
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  //// Delivery Per Invoices Option
  const perInvoicesOptions = {
    series: perInvoicesData,
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
      stackType: "100%",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "center",
          hideOverflowingLabels: false,
        },
      },
    },
    colors: perInvoicesColors,
    stroke: { width: 1, colors: ["#fff"] },
    xaxis: { categories: [""] },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(2)}%`,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)}%`,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    fill: { opacity: 1 },
    legend: { position: "top", horizontalAlign: "center", offsetX: 40 },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: { filter: { type: "none", value: 0 } },
    },
  };
  //// Day wise Analysis Option
  const ApexAnalysisOptions = {
    series: [
      {
        name: "Actual Days",
        data: actualDayWiseSeries1,
      },
      { name: "Tentative Days", data: tentativeDayWiseSeries2 },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        toolbar: { show: false },
        background: "#ffffff",
      },
      theme: {
        mode: "dark",
      },
      colors: ["#9260FF", "#AEE986"],
      stroke: { width: 1, colors: ["#fff"] },
      dataLabels: { formatter: (val) => val },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
      },
      xaxis: {
        categories: [
          ...(isO2WPage ? ["Order Days", "Shipment Booking Days"] : []),
          "Dispatch Days",
          "BL Days",
          "ETD Days",
          "ETA Port Days",
          "Contingency Days",
        ],
        labels: {
          style: {
            colors: "#000000",
          },
        },
      },
      fill: { opacity: 1 },
      colors: ["#80c7fd", "#00E396"],
      yaxis: {
        labels: {
          style: {
            colors: "#000000",
          },
          formatter: (val) => val,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        labels: {
          colors: "#000000",
        },
      },
    },
  };

  // const categoriesLabelChart = [
  //   "Order Date",
  //   "Shipment Booking Date",
  //   "Dispatch Date",
  //   "BL Date",
  //   "ETD",
  //   "ETA Port",
  //   "Delivery Date",
  // ];
  const isMobile = window.innerWidth <= 768;
  const labelFontSize = isMobile ? "5px" : "12px";
  const DayWiseAnalysisOptions = {
    series: [
      {
        name: "Actual Date",
        data: timelineSeries2,
      },
      {
        name: "Tentative Date",
        data: timelineSeries1,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      dataLabels: { enabled: false },
      stroke: { curve: "straight" },
      grid: { row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 } },
      tooltip: {
        x: { show: true, format: "dd MMM" },
        theme: "dark",
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 10,
          offsetY: -60,
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          style: {
            fontSize: labelFontSize,
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
            whiteSpace: "nowrap",
          },
          useHTML: true,
          formatter: function (value, timestamp, index) {
            const categoryLabel =
              value !== 0 && value !== "" && value !== undefined
                ? value?.split(" - ")[0]
                : "";
            const tentativeDays =
              value !== 0 && value !== "" && value !== undefined
                ? `${value?.split(" - ")[1]} Days`
                : "0 Days";
            return [categoryLabel, "", tentativeDays, "Tentative"];
          },
        },
        categories: timelineSeries2,
        crosshairs: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value ? moment(value).format("DD/MM/YYYY") : "0";
          },
        },
        title: {
          text: "Assumption Date",
        },
        type: "datetime",
      },
      legend: { position: "top", horizontalAlign: "center" },
    },
  };

  return (
    <Fragment>
      <Spin size="large" spinning={isLoading}>
        {isO2WPage && (
          <div className="row">
            <div className="col-md-3">
              <div className="card overview_tab_cards">
                <div className="card-content">
                  <div className="card-body d-flex justify-content-between">
                    <div className="media-body text-left overview_text">
                      <h3 className="text-success">
                        {overviewCountsDetails?.total_Ordered}
                      </h3>
                      <span>
                        Total Ordered (
                        {overviewCountsDetails?.total_Ordered_Percentage}%)
                      </span>
                    </div>
                    <div className="align-self-center">
                      <img src={imgOrder} className="overCard_img" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card overview_tab_cards">
                <div className="card-content">
                  <div className="card-body d-flex justify-content-between">
                    <div className="media-body text-left overview_text">
                      <h3 className="text-orange">
                        {overviewCountsDetails?.completed}
                      </h3>
                      <span>
                        Delivered ({overviewCountsDetails?.completed_Percentage}
                        %)
                      </span>
                    </div>
                    <div className="align-self-center">
                      <img
                        src={imgDelivered}
                        className="overCard_img"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card overview_tab_cards">
                <div className="card-content">
                  <div className="card-body d-flex justify-content-between">
                    <div className="media-body text-left overview_text">
                      <h3 className="text-info">
                        {overviewCountsDetails?.production}
                      </h3>
                      <span>
                        In Production (
                        {overviewCountsDetails?.production_Percentage}
                        %)
                      </span>
                    </div>
                    <div className="align-self-center">
                      <img
                        src={imgProduction}
                        className="overCard_img"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card overview_tab_cards">
                <div className="card-content">
                  <div className="card-body d-flex justify-content-between">
                    <div className="media-body text-left overview_text">
                      <h3 className="text-warning">
                        {overviewCountsDetails?.transit}
                      </h3>
                      <span>
                        In Transit ({overviewCountsDetails?.transit_Percentage}
                        %)
                      </span>
                    </div>
                    <div className="align-self-center">
                      <img
                        src={imgTransit}
                        className="overCard_img"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row card_padding">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
                <h5 className="content-header-title mb-4 d-inline-block">
                  Delivery Per Invoices
                </h5>
                <div className="delivery_chart_data">
                  <div className="wid_10">
                    <p className="chart_left_lbl">{name}</p>
                  </div>
                  <div className="wid_90">
                    <ReactApexChart
                      options={perInvoicesOptions}
                      series={perInvoicesOptions.series}
                      type="bar"
                      height={170}
                    />
                  </div>
                </div>
                <Table
                  bordered
                  className="dashboard_tables"
                  scroll={{ x: 900 }}
                  dataSource={deliveryPerInvoicesData}
                  columns={[
                    {
                      title: "Sr No.",
                      dataIndex: "Sr_No",
                      width: 100,
                      render: (_i, row, index) => {
                        return <div>{index + 1}</div>;
                      },
                    },
                    {
                      title: "Invoice Number",
                      dataIndex: "invoice_Number",
                      width: 200,
                      render: (_i, row) => {
                        return (
                          <div className="invoice_card_no">
                            <label
                              style={{
                                backgroundColor:
                                  row.delay_Color_Code === "light green"
                                    ? "#90EE90"
                                    : row.delay_Color_Code,
                              }}
                            >
                              {row.invoice_Number}
                            </label>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Quantity",
                      dataIndex: "sale_Quantity",
                      width: 200,
                      render: (_i, row) => {
                        return (
                          <div>{row.sale_Quantity + " " + row.sale_Unit}</div>
                        );
                      },
                    },
                    {
                      title: "Estimated Dispatch Date",
                      dataIndex: "estimated_Plan_Date",
                      width: 200,
                      render: (_i, row) => {
                        return (
                          <div>
                            {row.estimated_Plan_Date
                              ? moment(row.estimated_Plan_Date).format(
                                  "DD-MM-YYYY"
                                )
                              : "N/A"}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Actual Dispatch Date",
                      dataIndex: "invoice_Date",
                      width: 200,
                      render: (_i, row) => {
                        return (
                          <div>
                            {row.invoice_Date
                              ? moment(row.invoice_Date).format("DD-MM-YYYY")
                              : "N/A"}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Delay Status",
                      dataIndex: "delivery_Status",
                      width: 200,
                      render: (_i, row) => {
                        return (
                          <div className="lbl_status">
                            <label
                              style={{
                                backgroundColor:
                                  row.delay_Color_Code === "light green"
                                    ? "green"
                                    : row.delay_Color_Code,
                              }}
                            >
                              {row.delivery_Status.split(":")[0]}
                              <p>{":" + row.delivery_Status.split(":")[1]}</p>
                            </label>
                          </div>
                        );
                      },
                    },
                  ]}
                  pagination={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content collapse show">
                <div className="card-body">
                  <h5 className="content-header-title mb-4 d-inline-block">
                    Day wise Analysis
                  </h5>
                  <div className="p-2">
                    <ReactApexChart
                      options={ApexAnalysisOptions.options}
                      series={ApexAnalysisOptions.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content collapse show">
                <div className="card-body">
                  <h5 className="content-header-title mb-4 d-inline-block">
                    Timeline Wise Analysis
                  </h5>
                  <div className="p-2">
                    <ReactApexChart
                      options={DayWiseAnalysisOptions.options}
                      series={DayWiseAnalysisOptions.series}
                      type="line"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default OverviewTabDetails;
