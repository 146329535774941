import React, { Fragment, useEffect, useState } from "react";
import { Card, Button, Select, DatePicker, TimePicker, Input } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import GlobalFilterDrawer from "../../components/GlobalFilterDrawer";
import { useSelector } from "react-redux";
import { exportFileImg } from "../Elite/imageAssets";
import CreateNewVisitorTable from "./Tables/createNewVisitorTable";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import DeleteConfirmationModal from "../../components/deleteConfirmationModal";

const { Option } = Select;
const { TextArea, Search } = Input;

const CreateNewVisitor = () => {
  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [visitorTableData, setVisitorTableData] = useState({});

  const [companyListData, setCompanyListData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [branchListData, setBranchListData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [visitorTypeListData, setvisitorTypeListData] = useState([]);
  const [selectedVisitorType, setSelectedVisitorType] = useState(null);
  const [selectedLunch, setSelectedLunch] = useState(null);
  const [nameInput, setNameInput] = useState("");
  const [contactInput, setContactInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [noOfVisitorInput, setNoOfVisitorInput] = useState("");
  const [purposeOfVisit, setPurposeOfVisit] = useState("");
  const [belongings, setBelongings] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const [companyError, setCompanyError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [lunchError, setLunchError] = useState(false);
  const [belongingsError, setBelongingsError] = useState(false);
  const [visitTypeError, setVisitTypeError] = useState(false);
  const [purposeError, setPurposeError] = useState(false);
  const [noOfVisitorError, setNoOfVisitorError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [branchError, setBranchError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [tableSearchData, setTableSearchData] = useState("");
  const [isUpdateDeletId, setIsUpdateDeletId] = useState("");
  const [isDeletMdlVisible, setIsDeletMdlVisible] = useState(false);
  const [inTime, setInTime] = useState(null);
  const [outTime, setOutTime] = useState(null);

  const [tableObjColumn, setTableObjColumn] = useState({
    sortColumn: "",
    orderBy: "",
    currentPage: "",
  });

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee);
  }, []);

  useEffect(() => {
    handleGetCompanyListData();
    handleGetBranchListData();
    handleGetVisitorTypeListData();
  }, []);

  useEffect(() => {
    handleGetVisitorTableData();
  }, [tableObjColumn, tableSearchData]);

  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };

  function hasFilterDataExist(obj) {
    return Object.values(obj).some((array) => array.length > 0);
  }

  //// table sorting column
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      sortColumn: data.sortColumn,
      orderBy: data.orderBy,
      currentPage: data.currentPage,
    });
  };

  ///dropdown change events
  const handleDropdownOnChange = (e, check) => {
    if (check === "company") {
      setSelectedCompany(e);
    } else if (check === "branch") {
      setSelectedBranch(e);
    } else if (check === "visitType") {
      setSelectedVisitorType(e);
    } else {
      setSelectedLunch(e);
    }
  };

  /// handle Date picker onchange
  const handleDateChange = (value, dateString) => {
    if (value && moment.isMoment(value)) {
      setSelectedDateTime(value);
    } else {
      setSelectedDateTime(null);
    }
  };

  /// Reset Form
  const handleResetFormData = () => {
    setSelectedCompany(null);
    setSelectedLunch(null);
    setSelectedVisitorType(null);
    setSelectedBranch(null);
    setSelectedDateTime(null);
    setAddressInput("");
    setBelongings("");
    setPurposeOfVisit("");
    setNoOfVisitorInput("");
    setCompanyNameInput("");
    setEmailInput("");
    setNameInput("");
    setContactInput("");
    setBranchError(false);
    setBelongingsError(false);
    setVisitTypeError(false);
    setPurposeError(false);
    setNoOfVisitorError(false);
    setCompanyNameError(false);
    setEmailError(false);
    setNameError(false);
    setContactError(false);
    setDateTimeError(false);
    setAddressError(false);
    setEmailValidationError(false);
    setLunchError(false);
    setCompanyError(false);
  };

  const handleFormValidation = () => {
    let isValidation = true;

    // Validate Company
    if (!selectedCompany) {
      setCompanyError(true);
      isValidation = false;
    } else {
      setCompanyError(false);
    }
    // Validate Branch
    if (selectedBranch === null) {
      setBranchError(true);
      isValidation = false;
    } else {
      setBranchError(false);
    }

    // Validate Lunch
    if (!selectedLunch) {
      setLunchError(true);
      isValidation = false;
    } else {
      setLunchError(false);
    }

    // Validate Date/Time
    if (!selectedDateTime) {
      setDateTimeError(true);
      isValidation = false;
    } else {
      setDateTimeError(false);
    }

    // Validate Name
    if (!nameInput.trim()) {
      setNameError(true);
      isValidation = false;
    } else {
      setNameError(false);
    }

    // Validate Contact
    if (!contactInput.trim()) {
      setContactError(true);
      isValidation = false;
    } else {
      setContactError(false);
    }

    // Validate Email
    if (!emailInput.trim()) {
      setEmailError(true);
      isValidation = false;
    } else {
      setEmailError(false);
      // Email format validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(emailInput)) {
        setEmailValidationError(true);
        isValidation = false;
      } else {
        setEmailValidationError(false);
      }
    }

    // Validate Company Name
    if (!companyNameInput.trim()) {
      setCompanyNameError(true);
      isValidation = false;
    } else {
      setCompanyNameError(false);
    }

    // Validate Number of Visitors
    if (!noOfVisitorInput.trim()) {
      setNoOfVisitorError(true);
      isValidation = false;
    } else {
      setNoOfVisitorError(false);
      const numberpattern = /^\d+$/;
      if (!numberpattern.test(noOfVisitorInput)) {
        setNumberError(true);
        isValidation = false;
      } else if (parseInt(noOfVisitorInput, 10) === 0) {
        setNumberError(true);
        isValidation = false;
      } else {
        setNumberError(false);
      }
    }

    // Validate Purpose of Visit
    if (!purposeOfVisit.trim()) {
      setPurposeError(true);
      isValidation = false;
    } else {
      setPurposeError(false);
    }

    // Validate Belongings
    if (!belongings.trim()) {
      setBelongingsError(true);
      isValidation = false;
    } else {
      setBelongingsError(false);
    }

    // Validate Visit Type
    if (selectedVisitorType === null) {
      setVisitTypeError(true);
      isValidation = false;
    }

    // Validate Address
    if (!addressInput.trim()) {
      setAddressError(true);
      isValidation = false;
    } else {
      setAddressError(false);
    }

    return isValidation;
  };
  const handleSaveFormData = () => {
    if (handleFormValidation()) {
      let inputData = {
        ...(isUpdateDeletId && { visitorsId: isUpdateDeletId }),
        companyId: selectedCompany.toString(),
        branchId: selectedBranch.toString(),
        visitorsName: nameInput,
        primaryEmail: emailInput,
        contact: contactInput,
        companyName: companyNameInput,
        belongings: belongings,
        date: selectedDateTime,
        numberofVisitor: noOfVisitorInput,
        purposeOfVisit: purposeOfVisit,
        lunch: selectedLunch,
        visitTypeId: selectedVisitorType.toString(),
        address: addressInput,
      };
      visitorManagementService
        .postCreateUpdateVisitorsAPI(inputData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            let finaldata = response.data.responseData;
            if (finaldata !== "") {
              NotificationManager.success(finaldata);
              handleGetVisitorTableData();
              handleResetFormData();
            } else {
              NotificationManager.error(response.data.message);
            }
            setIsUpdateDeletId("");
          }
        })
        .catch((error) => {
          console.log(error, "Error");
        });
    }
  };

  const handleTblSearchChange = (e) => {
    setTableSearchData(e.target.value);
  };
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    handleGetVisitorTableData();
  };
  const handleDeleteData = (id) => {
    setIsDeletMdlVisible(true);
    setIsUpdateDeletId(id);
  };
  /// handle close delete modal
  const handleCloseDeleteMdl = () => {
    setIsDeletMdlVisible(false);
    setIsUpdateDeletId("");
  };

  /// Get company list API
  const handleGetCompanyListData = () => {
    visitorManagementService
      .getCompanyListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setCompanyListData(finaldata);
          } else {
            setCompanyListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get branch list API
  const handleGetBranchListData = () => {
    visitorManagementService
      .getBranchListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setBranchListData(finaldata);
          } else {
            setBranchListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get Visitor Type list API
  const handleGetVisitorTypeListData = () => {
    visitorManagementService
      .getVisitorTypeListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setvisitorTypeListData(finaldata);
          } else {
            setvisitorTypeListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get Visitor Table list API
  const handleGetVisitorTableData = () => {
    setIsTableLoading(true);
    let inputData = {
      pageindex: tableObjColumn.currentPage !== "" ? tableObjColumn?.currentPage : 1,
      pagesize: 10,
      sortcolumn: tableObjColumn !== "" ? tableObjColumn?.sortColumn : "",
      orderBy: tableObjColumn.orderBy !== "" ? tableObjColumn?.orderBy : "desc",
      search: tableSearchData,
      isReport: false,
    };
    visitorManagementService
      .getVisitorManagementTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (Object.keys(finaldata).length > 0) {
            setVisitorTableData(finaldata);
          } else {
            setVisitorTableData({});
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsTableLoading(false);
      })
      .catch((error) => {
        setIsTableLoading(false);
        console.log(error, "Error");
      });
  };
  /// Delete API
  const handleDeleteAPICalled = () => {
    visitorManagementService
      .deleteVisitorAPI(isUpdateDeletId)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata === "Deleted Successfully") {
            NotificationManager.success("Record Deleted Successfully!");
            handleGetVisitorTableData();
            setIsDeletMdlVisible(false);
            setIsUpdateDeletId("");
          } else {
            NotificationManager.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  ///Export data API
  const handleExportVisitorData = () => {
    setIsTableLoading(true);
    const currentDate = moment().format("YYYY-MM-DD");

    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: "",
      orderBy: "",
      search: "",
      isReport: false,
    };

    visitorManagementService
      .exportVisitorListAPI(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Visitor_Report_${currentDate}.xlsx`);

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);

        NotificationManager.success("Excel Export Successful!");
      })
      .catch((error) => {
        NotificationManager.error("Excel export failed.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  /// Print visitor card API
  const handlePrintVisitorcard = (id) => {
    setIsTableLoading(true);
    visitorManagementService
      .printVisitorCardAPI(id)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL, "_blank");
      })
      .catch((error) => {
        NotificationManager.error("Print failed.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  const handleEditData = (editData) => {
    const tempDateTime = moment(editData.date);
    setIsUpdateDeletId(editData.visitorsId);
    setSelectedCompany(+editData.companyId);
    setSelectedLunch(editData.lunch);
    setSelectedDateTime(tempDateTime);
    setSelectedVisitorType(+editData.visitTypeId);
    setSelectedBranch(+editData.branchId);
    setAddressInput(editData.address);
    setBelongings(editData.belongings);
    setPurposeOfVisit(editData.purposeOfVisit);
    setNoOfVisitorInput(editData.numberofVisitor);
    setCompanyNameInput(editData.companyName);
    setEmailInput(editData.primaryEmail);
    setNameInput(editData.visitorsName);
    setContactInput(editData.contact);
  };

  return (
    <Fragment>
      <div className="app-content content visitorPass">
        <div className="content-wrapper">
          <div className="content-body">
            {hasFilterDataExist(applyFilterObj) && (
              <h5 className="appled_filter">Applied Filters</h5>
            )}
            <h5 className="content-header-title d-inline-block">
              Create New Visitor
            </h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>

            <div className="row">
              <div className="col-xl-4 col-lg-4 col-12">
                <div className="card ">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="ft-16 fnt_w_bold">Detail One</h4>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            allowClear
                            placeholder="Select Company"
                            onChange={(e) =>
                              handleDropdownOnChange(e, "company")
                            }
                            value={selectedCompany}
                            optionFilterProp="children"
                          >
                            {companyListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.company}
                                </Option>
                              );
                            })}
                          </Select>
                          {companyError && (
                            <p className="inputError">
                              Company Name is Required!
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            allowClear
                            placeholder="Select Branch"
                            onChange={(e) =>
                              handleDropdownOnChange(e, "branch")
                            }
                            value={selectedBranch}
                            optionFilterProp="children"
                          >
                            {branchListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.branch_Name}
                                </Option>
                              );
                            })}
                          </Select>
                          {branchError && (
                            <p className="inputError">
                              Branch Name is Required!
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Name"
                            className="brdr_20"
                            value={nameInput}
                            onChange={(e) => setNameInput(e.target.value)}
                          />
                          {nameError && (
                            <p className="inputError">Name is Required!</p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Contact"
                            className="brdr_20"
                            value={contactInput}
                            onChange={(e) => setContactInput(e.target.value)}
                          />
                          {contactError && (
                            <p className="inputError">Contact is Required!</p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Email"
                            className="brdr_20"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                          />
                          {emailError && (
                            <p className="inputError">Email is Required!</p>
                          )}
                          {emailValidationError && (
                            <p className="inputError">
                              Please enter valid email!
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Company Name"
                            className="brdr_20"
                            value={companyNameInput}
                            onChange={(e) =>
                              setCompanyNameInput(e.target.value)
                            }
                          />
                          {companyNameError && (
                            <p className="inputError">
                              Company Name is Required!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-12 px-1">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body ">
                      <h4 className="ft-16 fnt_w_bold">Detail Two</h4>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Number of Visitor"
                            className="brdr_20"
                            value={noOfVisitorInput}
                            onChange={(e) =>
                              setNoOfVisitorInput(e.target.value)
                            }
                          />
                          {noOfVisitorError && (
                            <p className="inputError">
                              Number of Visitor is Required!
                            </p>
                          )}
                          {numberError && (
                            <p className="inputError">
                              Only digits are allowed.
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            placeholder="Lunch"
                            onChange={(e) => handleDropdownOnChange(e, "lunch")}
                            value={selectedLunch}
                            optionFilterProp="children"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                          {lunchError && (
                            <p className="inputError">Lunch is Required!</p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Purpose of Visit"
                            className="brdr_20"
                            value={purposeOfVisit}
                            onChange={(e) => setPurposeOfVisit(e.target.value)}
                          />
                          {purposeError && (
                            <p className="inputError">
                              Purpose of Visit is Required!
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            allowClear
                            placeholder="Visit Type"
                            onChange={(e) =>
                              handleDropdownOnChange(e, "visitType")
                            }
                            value={selectedVisitorType}
                            optionFilterProp="children"
                          >
                            {visitorTypeListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.visitType}
                                </Option>
                              );
                            })}
                          </Select>
                          {visitTypeError && (
                            <p className="inputError">
                              Visit Type is Required!
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div className="row pt-3">
                        <div className="col-12 px-1">
                          <Input
                            value={addressInput}
                            placeholder="User Inputs"
                            className="brdr_20"
                            onChange={(e) => setAddressInput(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            placeholder="Belongings"
                            className="brdr_20"
                            value={belongings}
                            onChange={(e) => setBelongings(e.target.value)}
                          />
                          {belongingsError && (
                            <p className="inputError">
                              Belongings is Required!
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <DatePicker
                            showTime
                            showNow={false}
                            showSecond={false}
                            placeholder="Select Date/Time"
                            value={selectedDateTime}
                            onChange={handleDateChange}
                          />
                          {dateTimeError && (
                            <p className="inputError">Date/Time is Required!</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body" style={{ minHeight: "184px" }}>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <TimePicker
                            placeholder="In Time"
                            format="h:mm A"
                            showNow={true}
                            value={inTime}
                            onChange={(time, timeString) =>
                              setInTime(timeString)
                            }
                            use12Hours
                          />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <TimePicker
                            placeholder="Out Time"
                            format="h:mm A"
                            showNow={true}
                            value={outTime}
                            onChange={(time, timeString) =>
                              setOutTime(timeString)
                            }
                            use12Hours
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-12 px-1">
                          <TextArea
                            value={addressInput}
                            placeholder="User Inputs"
                            autoSize={{ minRows: 3.5, maxRows: 3.5 }}
                            className="brdr_20"
                            onChange={(e) => setAddressInput(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="reset_save_btn" style={{ paddingTop: "0" }}>
              <Button className="reset_btn" onClick={handleResetFormData}>
                Reset
              </Button>
              <Button className="save_btn" onClick={handleSaveFormData}>
                Save
              </Button>
            </div>
            <div className="vertical_line" />
            <div className="gate_pass_section">
              <div className="row payment_tab">
                <label className="analy_type">Visitor Data</label>
              </div>
              <div className="d-flex">
                <div className="tab_searchtxt pb_0">
                  <Search
                    placeholder="Search..."
                    className="dash_search"
                    allowClear
                    onSearch={handleOnSearchData}
                    size="large"
                    value={tableSearchData}
                    onChange={handleTblSearchChange}
                  />
                </div>
                <div className="export_lbl">
                  <label onClick={handleExportVisitorData}>
                    Export
                    <img src={exportFileImg} alt="img" />
                  </label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <CreateNewVisitorTable
                    visitorTableData={visitorTableData}
                    visitorLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    handleDeleteData={handleDeleteData}
                    handleEditData={handleEditData}
                    handlePrintVisitorcard={handlePrintVisitorcard}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        visible={isDeletMdlVisible}
        onDelete={handleDeleteAPICalled}
        onCancel={handleCloseDeleteMdl}
      />
      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default CreateNewVisitor;
