import React, { Fragment, useEffect, useState } from "react";
import { editIcon, deleteIcon } from "../../Elite/imageAssets";
import { Table, Tooltip } from "antd";

const GatePassTable = ({
  gatePassTableData,
  gatePassLoading,
  OnhandleTableSortColumn,
  handleDeleteData,
  handleEditData,
}) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (gatePassTableData?.gatePassRecords?.length > 0) {
      setTotalRecords(gatePassTableData.totalRecords);
      setPageNo(Number(gatePassTableData.currentPage));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [gatePassTableData.gatePassRecords]);
//// table column sorting and pagination code
  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      currentPage: pagination.current,
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
    });
  };

  const tableColumns = [
    {
      title: "Sr No",
      dataIndex: "",
      sorter: false,
      showSorterTooltip: false,
      width: 80,
      render: (_i, row, index) => {
        return (
          <div>
            <label>{index + 1}</label>
          </div>
        );
      },
    },
    {
      title: "People",
      dataIndex: "numberOfPeople",
      sorter: (a, b) => a.numberOfPeople - b.numberOfPeople,
      showSorterTooltip: false,
      width: 100,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.numberOfPeople}</label>
          </div>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName - b.companyName,
      showSorterTooltip: false,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.companyName}</label>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.date}</label>
          </div>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      sorter: (a, b) => a.time - b.time,
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.time}</label>
          </div>
        );
      },
    },
    {
      title: "Lunch",
      dataIndex: "lunch",
      sorter: (a, b) => a.lunch - b.lunch,
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.lunch}</label>
          </div>
        );
      },
    },
    {
      title: "Transport",
      dataIndex: "transport",
      sorter: (a, b) => a.transport - b.transport,
      showSorterTooltip: false,
      width: 160,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.transport}</label>
          </div>
        );
      },
    },
    {
      title: "Car Number",
      dataIndex: "carNumber",
      sorter: (a, b) => a.carNumber - b.carNumber,
      showSorterTooltip: false,
      width: 180,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.carNumber}</label>
          </div>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks - b.remarks,
      showSorterTooltip: false,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            <Tooltip placement="bottom" title={row.remarks}>
              {row.remarks?.length > 26
                ? row.remarks.substr(0, 26).concat("...")
                : row.remarks}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (_text, _record, index) => (
        <div className="table_action_img">
          <div>
            <img
              src={editIcon}
              alt="img"
              onClick={() => handleEditData(_record)}
            />
          </div>
          <div className="line" />
          <div>
            <img
              src={deleteIcon}
              alt="img"
              className="ml-8"
              onClick={() => handleDeleteData(_record.id)}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="gatePassTbl payment_table"
        columns={tableColumns}
        dataSource={gatePassTableData.gatePassRecords}
        loading={gatePassLoading}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
      />
    </Fragment>
  );
};

export default GatePassTable;
