import React, { Fragment, useEffect, useState } from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment";
import {
  setTableListData,
  setTableActionDetails,
} from "../../actions/tableListing/tableListDataAction";

const LogisticPendingTab = ({
  tabTableData,
  isLoading,
  OnhandleTableSortColumn,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isO2WPage =
    window.location.pathname.split("/")[1] === "dashboard" ? true : false;

  const tableSorting = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (tabTableData.logisticsPendingDispatchTab?.length > 0) {
      setTotalRecords(tabTableData.totalRecords);
      setPageNo(Number(tabTableData.currentPage));
      dispatch(setTableListData(tabTableData.logisticsPendingDispatchTab));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [tabTableData.logisticsPendingDispatchTab]);

  /// table sorting code with pagination
  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
      currentPage: pagination.current,
    });
  };

  /// handle overview Details page code
  const handleOverviewDetailsPage = (rowData) => {
    dispatch(
      setTableActionDetails({
        labelId: rowData.customer_PO_Number,
        valueName: rowData.customer_PO_Number,
        SapOrderNumber: rowData.sap_Order_Number,
      })
    );
    navigate(
      `/${
        isO2WPage ? "o2w" : "logistic"
      }/overviewDetails/Pending Dispatch/${rowData.customer_PO_Number.replace(
        /[ \/]/g,
        ""
      )}`
    );
  };

  const tableColumns = [
    {
      title: "Customer Name",
      dataIndex: "customer_Organization_Name",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_Organization_Name"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 180,
      render: (_i, row) => {
        return (
          <div>
            <label>
              {row.customer_Organization_Name
                ? row.customer_Organization_Name
                : "N/A"}
            </label>
          </div>
        );
      },
    },
    {
      title: "Port Name",
      dataIndex: "portName",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "portName"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 180,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.portName ? row.portName : "N/A"}</label>
          </div>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "customer_PO_Date",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_PO_Date"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            {row.customer_PO_Date
              ? moment(row.customer_PO_Date).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Estimated Dispatch Date",
      dataIndex: "dispatch_Estimated",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "dispatch_Estimated"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            {row.dispatch_Estimated
              ? moment(row.dispatch_Estimated).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Booking Date",
      dataIndex: "shipment_Booking_Actual",
      sorter: true,
      showSorterTooltip: false,
      width: 230,
      sortOrder:
        tableSorting.sortColumn === "shipment_Booking_Actual"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            {row.shipment_Booking_Actual
              ? moment(row.shipment_Booking_Actual).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      fixed: "right",
      render: (_i, row) => (
        <div
          className="tbl_info_details"
          onClick={() => handleOverviewDetailsPage(row)}
        >
          <InfoCircleTwoTone />
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="logistic_tables"
        dataSource={tabTableData.logisticsPendingDispatchTab}
        loading={isLoading}
        onChange={handleTableAscDes}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "even-row" : "odd-row"
        }
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        columns={tableColumns}
      />
    </Fragment>
  );
};

export default LogisticPendingTab;
