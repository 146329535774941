import React, { Fragment, useEffect, useState } from "react";
import { editIcon } from "../../Elite/imageAssets";
import { Table, TimePicker } from "antd";
import moment from "moment";
import visitorManagementService from "../../../services/VisitorManagement/visitorManagement-service";
import { NotificationManager } from "react-notifications";

const ReceptionReportTable = ({
  receptionReportData,
  gatePassLoading,
  OnhandleTableSortColumn,
  fetchReportData,
}) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [editingRow, setEditingRow] = useState(null);
  const [updatedRecords, setUpdatedRecords] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  useEffect(() => {
    if (receptionReportData?.gatePassRecords) {
      setUpdatedRecords([...receptionReportData.gatePassRecords]);
      setTotalRecords(receptionReportData.totalRecords || 0);
      setPageNo(Number(receptionReportData.currentPage));
    }
  }, [receptionReportData]);

  //// handle table sorting code
  const handleTableChange = (pagination, filters, sorter) => {
    OnhandleTableSortColumn(pagination, filters, sorter);
  };
//// handle time onchange code
  const handleTimeChange = (time, timeString, field, record) => {
    const updatedData = updatedRecords.map((item) => {
      if (item.id === record.id) {
        return { ...item, [field]: timeString };
      }
      return item;
    });
    setUpdatedRecords(updatedData);
  };
///handle edit click code 
  const handleEditClick = (record) => {
    if (editingRow === record.id) {
      setEditingRow(null);
    } else {
      setEditingRow(record.id);
    }
  };

  //// API called for save data
  const handleSaveClick = (record) => {
    const updatedRecord = updatedRecords.find((item) => item.id === record.id);

    const inputData = {
      id: updatedRecord.id,
      inTime: updatedRecord.inTime || "",
      outTime: updatedRecord.outTime || "",
    };

    visitorManagementService
      .postUpdateTime(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const successMessage =
            response.data.responseData || "Data updated successfully!";
          if (
            successMessage.includes(
              "Record can be updated for the current date only"
            )
          ) {
            NotificationManager.warning(successMessage);
            setEditingRow(null);
          } else {
            NotificationManager.success(successMessage);
            setUpdatedRecords((prevRecords) =>
              prevRecords.map((item) =>
                item.id === record.id
                  ? { ...item, vehicleId: inputData.vehicleId }
                  : item
              )
            );

            setEditingRow(null);

            fetchReportData();
          }
        } else {
          NotificationManager.error(
            response.data.responseData || "Update failed."
          );
        }
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        NotificationManager.error("Update failed.");
      });
  };

  // Handle row selection changes
  const onSelectChange = (selectedKeys, selectedRows) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedRowsData(selectedRows);
  };

  /// row selection code
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.disabled,
    }),
  };

  const tableColumns = [
    {
      title: "Sr No",
      dataIndex: "",
      sorter: false,
      showSorterTooltip: false,
      width: 80,
      render: (_i, row, index) => {
        return (
          <div>
            <label>{index + 1}</label>
          </div>
        );
      },
    },
    {
      title: "People",
      dataIndex: "people",
      sorter: (a, b) => a.people - b.people,
      showSorterTooltip: false,
      width: 100,
      render: (_i, row) => <label>{row.numberOfPeople}</label>,
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      showSorterTooltip: false,
      width: 170,
      render: (_i, row) => <label>{row.companyName}</label>,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => <label>{row.date}</label>,
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      width: 130,
      sorter: (a, b) =>
        moment(a.inTime, "h:mm A").unix() - moment(b.inTime, "h:mm A").unix(),
      showSorterTooltip: false,
      render: (_i, row) => {
        const record = updatedRecords.find((item) => item.id === row.id) || row;
        return editingRow === row.id ? (
          <TimePicker
            format="h:mm A"
            showNow={false}
            value={record.inTime ? moment(record.inTime, "h:mm A") : null}
            onChange={(time, timeString) =>
              handleTimeChange(time, timeString, "inTime", row)
            }
            use12Hours
          />
        ) : (
          <label>{record.inTime}</label>
        );
      },
    },
    {
      title: "Out Time",
      dataIndex: "outTime",
      width: 130,
      sorter: (a, b) =>
        moment(a.outTime, "h:mm A").unix() - moment(b.outTime, "h:mm A").unix(),
      showSorterTooltip: false,
      render: (_i, row) => {
        const record = updatedRecords.find((item) => item.id === row.id) || row;
        return editingRow === row.id ? (
          <TimePicker
            format="h:mm A"
            showNow={false}
            value={record.outTime ? moment(record.outTime, "h:mm A") : null}
            onChange={(time, timeString) =>
              handleTimeChange(time, timeString, "outTime", row)
            }
            use12Hours
          />
        ) : (
          <label>{record.outTime}</label>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: 200,
      render: (_i, row) => <label>{row.remarks}</label>,
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 80,
      render: (_text, record) => (
        <div
          className="table_action_img"
          onClick={() =>
            editingRow === record.id
              ? handleSaveClick(record)
              : handleEditClick(record)
          }
          style={{ cursor: "pointer" }}
        >
          {editingRow === record.id ? (
            <span style={{ color: "#3cafda" }}>Save</span>
          ) : (
            <img src={editIcon} alt="Edit" />
          )}
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="gatePassTbl payment_table"
        columns={tableColumns}
        dataSource={updatedRecords}
        loading={gatePassLoading}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        pagination={{
          total: totalRecords,
          current: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
      />
    </Fragment>
  );
};

export default ReceptionReportTable;
