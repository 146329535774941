//Dashboard Images
export const imgKnackLogo = require("../../assets/images/knackLogo.png");
export const SmallOrder = require("../../assets/images/SmallOrder.png");
export const OrderBG = require("../../assets/images/OrderBg.png");
export const Material = require("../../assets/images/White_MTQ.png");
export const SmallMaterial = require("../../assets/images/Small_M.png");
export const SmallQuantity = require("../../assets/images/Small_Q.png");
export const SmallMTQ = require("../../assets/images/White_MTQ.png");
export const imgProduction = require("../../assets/images/Production.png");
export const imgTransit = require("../../assets/images/Transition.png");
export const imgPending = require("../../assets/images/TotalPending.png");
export const imgDelivered = require("../../assets/images/Delivered.png");
export const imgOrder = require("../../assets/images/Order.png");
export const imgSquare = require("../../assets/images/Square.png");
export const imgEqual = require("../../assets/images/Equal.png");
export const imgTopArrwo = require("../../assets/images/top-arrow.png");
export const imgDelete = require("../../assets/images/remove.png");

//Timeline Tab Images
export const Anchor = require("../../assets/images/anchor.png");
export const Check = require("../../assets/images/check.png");
export const Confirm = require("../../assets/images/confirm.png");
export const DeliveryTrack = require("../../assets/images/delivery-truck.png");
export const Delivery = require("../../assets/images/delivery.png");
export const Documents = require("../../assets/images/documents.png");
export const plane = require("../../assets/images/plane.png");
export const Shipment = require("../../assets/images/shipment.png");
export const VobFile = require("../../assets/images/vob-file.png");
export const BLDocument = require("../../assets/images/BL Doc.png");
export const imgCubes = require("../../assets/images/cubes.png");
export const imgDownload = require("../../assets/images/download.png");

/// Payment Image
export const imgBank = require("../../assets/images/payment_bank.png");
export const imgFilter = require("../../assets/images/filter.png");
export const paymentSmall_1 = require("../../assets/images/payment1sm.png");
export const paymentSmall_2 = require("../../assets/images/payment2sm.png");
export const paymentSmall_3 = require("../../assets/images/payment3sm.png");
export const paymentSmall_4 = require("../../assets/images/payment4sm.png");

/// Logistic Image
export const LogTotalDis = require("../../assets/images/logistic_total.png");
export const LogTotalLg = require("../../assets/images/logTotalLg.png");
export const LogPendingDis = require("../../assets/images/logistic_ pen.png");
export const LogTransitDis = require("../../assets/images/logistic_ tran.png");
export const LogDeliveredDis = require("../../assets/images/logistic_deliverd.png");

// visitor Management Image
export const visitorImg = require("../../assets/images/visitor.png");
export const officeImg = require("../../assets/images/office.png");
export const factoryImg = require("../../assets/images/factory.png");
export const editIcon = require("../../assets/images/Edit.png");
export const deleteIcon = require("../../assets/images/Delete.png");
export const exportFileImg = require("../../assets/images/exportFile.png");
export const pdfImg = require("../../assets/images/pdgIcon.png");
export const printImg = require("../../assets/images/print.png");
export const reportImg = require("../../assets/images/report.png");
